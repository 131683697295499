import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Drawer as BPDrawer } from '@blueprintjs/core'

const Drawer = styled(BPDrawer)`
  &&& {
    padding: 2.5rem;
  }
`

const _ = ({ children, ...props }) => (
	<Drawer {...props}>
		{children}
	</Drawer>
)
_.propTypes = {
	/** Children elements will render inside the body of the drawer */
	children: PropTypes.node,
	/** Decides if close button appears within Drawer content */
	isCloseButtonShown: PropTypes.bool
}
_.defaultProps = {
	isCloseButtonShown: true
}

export default _
