import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MenuItem as BPMenuItem } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'

/**
 * Styled Menu Item that goes within the Select Component
 */
const StyledMenuItem = styled(BPMenuItem)`
  && {
    text-align: left;

    .bp3-menu-item-label {
        width: 100%;
    }

    &.bp3-active {
    }
  }
`

const MenuItem = (props) => <StyledMenuItem {...props} />
MenuItem.propTypes = {
	/** The width of the select field (default: auto) */
	width: PropTypes.number
}

/**
 * Styled Select
 */
const StyledSelect = styled(Select)`
  && {
    .bp3-popover-target {
			width: 100%;
    }
  }
`

const _ = (props) => <StyledSelect {...props}/>

_.ItemPredicate = (query, item) => {
	return item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0
}
_.ItemRenderer = function renderX(item, { handleClick, modifiers }) {
	if (!modifiers.matchesPredicate) {
		return null
	}
	return (
		<MenuItem
			active={modifiers.active}
			disabled={modifiers.disabled}
			label={item.label}
			key={item.id}
			onClick={handleClick}
		/>
	)
}
_.propTypes = {
	/** The width of the select field (default: auto) */
	width: PropTypes.number
}
_.defaultProps = {
}
export { _ as Select, MenuItem as SelectItem }
