import React from 'react'
import styled from 'styled-components'
import { Checkbox } from 'evergreen-ui'

import useStateWithLocalStorage from '../../../hooks/useStateWithLocalStorage'
import { Typography } from '../../primitives'

const Instructions = styled(Typography)`
  ul {
    margin-top: 1.25rem;
		margin-left: 1.25rem;
    margin-bottom: 3.25rem;
  }

	li {
		margin-top: .5rem;
		margin-bottom: .5rem;
	}
`
const VideoContainer = styled.div`
  overflow: hidden;
  position: relative;
  width:100%;
  margin: 15px 0;

  /* Medium devices (tablets, 768px and up) */
	@media (min-width: 768px) { 
    width:40%;
	}

  &::after {
    padding-top: 56.25%;
    /* If your video is 4:3 for example, set it to 75%. */
    display: block;
    content: '';
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const WelcomePanel = () => {

	const [value, setValue] = useStateWithLocalStorage('hideUtterWelcome')
	
	return (
		<>
			<Typography variant="h5" weight="black" className="mb-2">Welcome to Utter!</Typography>
			<Typography variant="body" weight="normal" className="mb-2">The game is simple:</Typography>
			<Instructions variant="small" weight="normal">
				<ul>
					<li>Players enter their name and Twitter user handle (no password needed)</li>
					<li>Your Twitter profile can&apos;t be private to play!</li>
					<li>No Twitter, no problem: use a celebrity&apos;s Twitter handle instead.</li>
					<li>Each player reads a tweet out loud and guesses who said it, then passes the phone.</li>
					<li>The game ends after all the rounds are complete.</li>
					<li>The team with the most points wins!</li>
				</ul>

				<VideoContainer>
					<iframe 
						src={'https://www.youtube.com/embed/Lc5fVd8umiQ'}
						frameBorder="0" 
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
						allowFullScreen/>
				</VideoContainer>
				<Checkbox
					label={<Typography variant="small" weight="bold">{'Don\'t show again'}</Typography>}
					checked={value}
					onChange={e => setValue(e.target.checked)}
				/>
			</Instructions>
		</>
	)
}

export default WelcomePanel
