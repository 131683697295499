import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@blueprintjs/core'
import { Context } from '../Context'

const StyledButton = styled(Button)`
  &&& {
    background-image: none;
    :hover, :disabled{
      opacity: .75;
    }
    box-shadow: none;
    outline: none;

    .bp3-button-text {
      color: ${({theme, color}) => `${color || theme.text}`};
      flex: 1;
    }

    .bp3-icon {
      color: inherit;
    }
    ${({theme, secondary, outline, backgroundColor}) => {
		if (secondary && outline) {
			return `
        background-color: ${backgroundColor || theme.trueWhite}
        :hover, :disabled {
          background-color: ${backgroundColor || theme.trueWhite}
        }
      `
		} if (outline) {
			return `
        background-color: ${backgroundColor || theme.trueWhite}
        :hover, :disabled {
          background-color: ${backgroundColor || theme.trueWhite}
        }
      `
		} if (secondary) {
			return `
        background-color: ${backgroundColor || theme.trueBlack}
        :hover, :disabled {
          background-color: ${backgroundColor || theme.trueBlack}
        }
      `
		}
		return `
      background-color: ${backgroundColor || theme.trueBlack}
      :hover, :disabled {
        background-color: ${backgroundColor || theme.trueBlack}
      }
    `
	}}
  }
`

const _ = ({onClick, sound, ...props}) => {

	const { sounds: soundsCtx, sfxOn: sfxOnCtx } = useContext(Context)
	const [ sounds ] = soundsCtx
	const [ sfxOn ] = sfxOnCtx

	const doSFX = () => {
		sfxOn && sounds[sound || 'buttonTap'].play()
	}

	return <StyledButton {...props} onClick={() => {onClick && onClick(); doSFX()}} />
}
_.propTypes = {
	/** Background color override */
	backgroundColor: PropTypes.string,
	/** Sets button to a primary button */
	secondary: PropTypes.bool,
	color: PropTypes.string,
	/** Sets background to white with only a border color */
	outline: PropTypes.bool,
	onClick: PropTypes.func,
	sound: PropTypes.string
}

export default _
