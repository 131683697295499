/**
 * Import / Export helper functions here
 */

const EMOJI_REGEX = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/gm
const URL_REGEX = '([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?'
 
export const getRandom = (arr, n) => {
	var result = new Array(n),
		len = arr.length,
		taken = new Array(len)
	if (n > len)
		throw new RangeError('getRandom: more elements taken than available')
	while (n--) {
		var x = Math.floor(Math.random() * len)
		result[n] = arr[x in taken ? taken[x] : x]
		taken[x] = --len in taken ? taken[len] : len
	}
	return result
}

export const removeEmojis = (string) => {
	return string.replace(EMOJI_REGEX, '')
}

export const removeURLs = (string) => {
	return string.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '')
}

export const onlyEmojis = (string) => {
	let rest = string.replace(/\s/g, '')
	rest = removeEmojis(rest)
	return rest.length === 0
}

export const hasUrlAndTextOnly = (tweet) => {
	const urlExists =  new RegExp(URL_REGEX).test(tweet.full_text)
		? true
		: false

	const hasImage = tweet.extended_entities && tweet.extended_entities.media.length > 0

	return urlExists && !hasImage
}

export const onlyURL = (tweet) => {
	const rest = removeURLs(tweet.full_text).replace(/\s/g, '')
	const hasImage = tweet.extended_entities && tweet.extended_entities.media.length > 0
	return rest.length === 0 && !hasImage
}

/**
 * 
 */
export const justBad = (tweet) => {
	const rest = removeEmojis(removeURLs(tweet.full_text)).replace(/\s/g, '')
	const hasImage = tweet.extended_entities && tweet.extended_entities.media.length > 0
	return rest.length === 0 && !hasImage
}

export const containsVideo = (tweet) => {
	if (!tweet.extended_entities || tweet.extended_entities.media.length === 0) return false

	let videoCount = 0
	tweet.extended_entities.media.forEach(item => {
		if (item.media_url && item.media_url.includes('ext_tw_video_thumb')) {
			videoCount++
		}
	})
	
	return videoCount > 0
}