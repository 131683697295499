export default [
	'Don\'t think of purple hippos...',
	'Have a good day.',
	'The architects are still drafting',
	'Would you prefer chicken, steak, or tofu?',
	'(Pay no attention to the man behind the curtain)',
	'Please wait while the little elves draw your map',
	'Don\'t worry - a few bits tried to escape, but we caught them',
	'Go ahead -- hold your breath!',
	'...at least you\'re not on hold...',
	'You\'re not in Kansas any more',
	'We\'re testing your patience',
	'Follow the white rabbit',
	'It\'s still faster than you could draw it',
	'My other loading screen is much faster.',
	'Testing on Timmy... We\'re going to need another Timmy.',
	'(Insert quarter)',
	'Are we there yet?',
	'Have you lost weight?',
	'Why so serious?',
	'It\'s not you. It\'s me.',
	'Counting backwards from Infinity',
	'Don\'t panic...',
	'Do not run! We are your friends!',
	'Do you come here often?',
	'We\'re making you a cookie.',
	'Spinning the wheel of fortune...',
	'I feel like im supposed to be loading something. . .',
	'Is this Windows?',
	'Adjusting flux capacitor...',
	'Please wait until the sloth starts moving.',
	'Don\'t break your screen yet!',
	'I swear it\'s almost done.',
	'Let\'s take a mindfulness minute...',
	'Unicorns are at the end of this road, I promise.',
	'Listening for the sound of one hand clapping...',
	'Keeping all the 1\'s and removing all the 0\'s...',
	'Cleaning off the cobwebs...',
	'Making sure all the i\'s have dots...',
	'We are not liable for any broken screens as a result of waiting.',
	'We need more dilithium crystals',
	'Granting wishes...',
	'Time flies when you’re having fun.',
	'99 bottles of beer on the wall..',
	'Load it and they will come',
	'Convincing AI not to turn evil..',
	'Your left thumb points to the right and your right thumb points to the left.',
	'Wait, do you smell something burning?',
	'When nothing is going right, go left!!...',
	'Winter is coming...',
	'Finding someone to hold my beer',
	'BRB, working on my side project',
	'@todo Insert witty loading message',
	'Definitely not a virus...',
	'You seem like a nice person...',
	'Still faster than Windows update',
	'Composer hack: Waiting for reqs to be fetched is less frustrating if you add -vvv to your command.'
]
