const Locale = {
	messages: {

	},
	errors: {
		TEAM_CONFIGURATION: {
			found_private_account: '🛑 Hmmm looks like the account you entered is private. This game wont work with private twitter accounts. Please try again.',
			invalid_twitter_handle: '🛑 The twitter handle you entered was invalid. Please try again.',
			invalid_name: '🛑 The name you entered was invalid. Please try again.',
			player_name_exists: '🛑 A player with that name already exist on that team. Please try again.',
			handle_exists: '🛑 You\'ve already added that twitter handle. Please try again.'
		}
	}
}

export default Locale