import React from 'react'
import styled from 'styled-components'
import { PanelStack as BPPanelStack } from '@blueprintjs/core'

const StyledFormPanelStack = styled(BPPanelStack)`
  &&{
    .bp3-panel-stack-view {
      position: relative;
      border-right: none;
      margin-right: 0;
    }

    .bp3-panel-stack-exit {
      height:0;
    }

    .bp3-panel-stack-header {
      display: none;
    }

    .bp3-panel-stack-header .bp3-heading {
      display: none;
    }
  }
`

const PanelStack = (props) => {
	return (
		<StyledFormPanelStack {...props} renderActivePanelOnly={true} />
	)
}

export default PanelStack
