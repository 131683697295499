import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import useStateWithLocalStorage from '../hooks/useStateWithLocalStorage'

const Context = React.createContext({})

const ContextProvider = ({children}) => {
	
	const {buttonTap, dialogsEntryExit, trash, ding, buzzer, bombTimer, themeMusic} = useStaticQuery(graphql`
		query {
			themeMusic: file(name: {eq: "ES_Beater Head - The New Fools"}, sourceInstanceName: {eq: "sounds"}) {
				publicURL
			}
			buttonTap: file(name: {eq: "ES_Switch Click 2 - SFX Producer"}, sourceInstanceName: {eq: "sounds"}) {
				publicURL
			}
			dialogsEntryExit: file(name: {eq: "ES_Whip,Whoosh 2 - SFX Producer"}, sourceInstanceName: {eq: "sounds"}) {
				publicURL
			}
			trash: file(name: {eq: "ES_Impact Trash Can 1 - SFX Producer"}, sourceInstanceName: {eq: "sounds"}) {
				publicURL
			}
			ding: file(name: {eq: "ES_Bell Ding - SFX Producer"}, sourceInstanceName: {eq: "sounds"}) {
				publicURL
			}
			buzzer: file(name: {eq: "ES_Game Show Buzzer 2 - SFX Producer"}, sourceInstanceName: {eq: "sounds"}) {
				publicURL
			}
			bombTimer: file(name: {eq: "ES_Bomb Timer 2 - SFX Producer"}, sourceInstanceName: {eq: "sounds"}) {
				publicURL
			}
		}
	`)
		
	const [gameMode, setGameMode] = useState('teams mode')
	const [category, setCategory] = useState([])
	const [rounds, setRounds] = useState('5')
	const [sounds, setSounds] = useState({})
	const [sfxOn, setSfxOn] = useStateWithLocalStorage('options_sfxOn')
	const [musicOn, setMusicOn] = useStateWithLocalStorage('options_musicOn')
	const [testModeOn, setTestModeOn] = useStateWithLocalStorage('options_testModeOn')

	// Work around https://github.com/gatsbyjs/gatsby/issues/19522
	useEffect(() => {
		setSounds(
			{
				buttonTap: new Audio(buttonTap.publicURL),
				dialogsEntryExit: new Audio(dialogsEntryExit.publicURL),
				trash: new Audio(trash.publicURL),
				ding: new Audio(ding.publicURL),
				buzzer: new Audio(buzzer.publicURL),
				bombTimer: new Audio(bombTimer.publicURL),
				themeMusic: new Audio(themeMusic.publicURL)
			}
		)
	}, [])

	return (
		<Context.Provider value={
			{
				gameMode: [gameMode, setGameMode],
				category: [category, setCategory],
				rounds: [rounds, setRounds],
				sfxOn:  [sfxOn, setSfxOn],
				musicOn:  [musicOn, setMusicOn],
				testModeOn:  [testModeOn, setTestModeOn],
				sounds:  [sounds, setSounds]
			}
		}>
			{children}
		</Context.Provider>
	)
}

ContextProvider.propTypes = {
	children: PropTypes.object
}

export { Context, ContextProvider }