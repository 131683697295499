import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Typography } from '../../primitives'

const Instructions = styled(Typography)`
  ul {
    margin-top: 1.25rem;
		margin-left: 1.25rem;
    margin-bottom: 3.25rem;
  }

	li {
		margin-top: .5rem;
		margin-bottom: .5rem;
	}
`

const ConfigureSolo = ({title, description}) => {

	return (
		<>
			<Typography variant="h5" weight="black" className="mb-2">{title}</Typography>
			<Instructions variant="small" weight="normal">
				<ul>
					<li>{description}</li>
					<li>Play against your friends and see who can guess the most tweets right.</li>
					<li>The game ends after all the rounds are complete.</li>
				</ul>
			</Instructions>
			<Typography variant="body" weight="normal" className="mb-2">Tap Start to begin</Typography>
		</>
	)
}

ConfigureSolo.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string
}

export default ConfigureSolo
