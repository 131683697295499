export default [
	'af',
	'wrong',
	'done',
	'why',
	'money',
	'hate',
	'greedy',
	'envy',
	'fire',
	'weird',
	'lil',
	'types of',
	'play',
	'fine',
	'cold',
	'mine',
	'weak',
	'heavy',
	'chill',
	'energy',
	'be ',
	'like',
	'me',
	'man',
	'women',
	'boyfriend',
	'girlfriend',
	'dope',
	'lol',
	'wtf',
	'bank',
	'account',
	'bathroom',
	'loser',
	'sexual',
	'tension',
	'boomer',
	'guy',
	'worried',
	'about',
	'single',
	'hot',
	'dog',
	'cat',
	'brother',
	'sister',
	'fucked',
	'hell',
	'hungry',
	'hangry',
	'lit',
	'party',
	'funny',
	'ball',
	'damn',
	'smoke',
	'weed',
	'white',
	'black',
	'accident',
	'ghetto',
	'fight',
	'knocked',
	'broke',
	'rich',
	'hired',
	'pay',
	'day',
	'friday',
	'night',
	'morning',
	'running',
	'working',
	'awards',
	'netflix',
	'hulu',
	'apple ',
	'android',
	'kanye',
	'festival',
	'concert',
	'thinking',
	'sleep',
	'freezing',
	'burning',
	'summer',
	'fall',
	'spring',
	'christmas',
	'valentines',
	'halloween',
	'costume',
	'mother',
	'father',
	'dad ',
	'mom',
	'body',
	'butt',
	'guns',
	'cnn',
	'fake',
	'miami',
	'atl',
	'atlanta',
	'vegas',
	'sin',
	'dallas',
	'ny',
	'new',
	'york',
	'seattle',
	'london',
	'paris',
	'mexico',
	'dc',
	'houston',
	'mountain',
	'vacation',
	'bae',
	'beer',
	'cush',
	'loud',
	'tattoo',
	'stocks',
	'airbnb',
	'apocolypse',
	'obama',
	'trump',
	'onion',
	'mask',
	'lebron',
	'james',
	'mj',
	'jordan',
	'superbowl',
	'super',
	'years',
	'vaccine',
	'police',
	'kill',
	'murder',
	'fuck',
	'shit',
	'niggas',
	'ass',
	'college',
	'bday',
	'nfl',
	'fantasy',
	'suck',
	'win',
	'lose',
	'espn',
	'lose',
	'sports',
	'basketball',
	'nba',
	'mlb',
	'major',
	'trip',
	'airline',
	'vacay',
	'cruise',
	'girl',
	'marijuana',
	'empire',
	'state',
	'effiel',
	'topgolf',
	'pool',
	'beach',
	'waterfall',
	'tom',
	'brady',
	'patriots',
	'newton',
	'gas',
	'road',
	'subway',
	'ticket',
	'fast',
	'slow',
	'jeep',
	'meg',
	'cardi',
	'1',
	'100',
	'flat',
	'kanye',
	'kim',
	'kardashian',
	'bankrupt',
	'fired',
	'expensive',
	'cheap',
	'nike',
	'adidas',
	'google',
	'amazon',
	'uber',
	'lyft',
	'at&t',
	'verizon',
	'starbucks',
	'walmart',
	'spirit',
	'delta',
	'friends',
	'pigeons',
	'roaches',
	'bee',
	'pet',
	'bite',
	'squirrels',
	'cousin',
	'grandma',
	'baby',
	'daddy',
	'mama',
	'eating',
	'throw',
	'trash',
	'good',
	'cooking',
	'suprised',
	'love',
	'dancing',
	'biking',
	'running',
	'pushup',
	'pelaton',
	'yoga',
	'weights',
	'situps',
	'swim',
	'bbq',
	'skiing',
	'dumped',
	'tinder',
	'hookup',
	'sex',
	'boobs',
	'dick',
	'cheated',
	'wife',
	'hate',
	'tv',
	'migos',
	'drake',
	'swipe',
	'warning',
	'god',
	'wedding',
	'easy',
	'nut',
	'wild',
	'slam',
	'dunk',
	'blowout',
	'dirty',
	'fresh',
	'fit',
	'traffic',
	'scary',
	'gross',
	'mouse',
	'asian',
	'yellow',
	'indian',
	'american',
	'usa',
	'bears',
	'eagles',
	'philly',
	'shoes',
	'feet',
	'kids',
	'21',
	'30',
	'dumbass',
	'zodiac',
	'sign',
	'mind',
	'broccoli',
	'die',
	'biden',
	'cake',
	'drug',
	'millenials',
	'government',
	'24',
	'all',
	'goals',
	'relationship',
	'entanglement',
	'situationship',
	'confused',
	'teachers',
	'boss',
	'clout',
	'free',
	'hour',
	'complicated',
	'makeup',
	'legit',
	'tbh',
	'laughing',
	'stfu',
	'lmfao',
	'stranger',
	'nyc',
	'halftime',
	'stripper',
	'gym',
	'2020',
	'ex',
	'baddie',
	'instagram',
	'ig',
	'drunk',
	'wasted',
	'tequila',
	'drive',
	'stupid',
	'craigslist',
	'athletic',
	'freak',
	'wow',
	'up',
	'bubbles',
	'bill',
	'procrastinate',
	'facebook',
	'cowboys',
	'lakers',
	'rap',
	'music',
	'country',
	'redneck',
	'karen',
	'bread',
	'oprah',
	'love',
	'batman',
	'superman',
	'dating',
	'pimple',
	'hair',
	'shave',
	'bleed',
	'ugh',
	'ahh',
	'high',
	'school',
	'fashion',
	'fat',
	'pregnant',
	'eggs',
	'green',
	'tiktok',
	'onlyfans',
	'movie',
	'best',
	'booze',
	'pics',
	'booty',
	'ha',
	'haha',
	'bar',
	'club',
	'bag',
	'chicken',
	'friday',
	'weekend',
	'nap',
	'florida',
	'sec',
	'acc',
	'chicago',
	'scam',
	'wap',
	'wypipo',
	'reality',
	'expectations',
	'poker',
	'gambling',
	'shot',
	'bomboclaat',
	'aunt',
	'uncle',
	'crying',
	'pyramid',
	'game',
	'nobody',
	'bff',
	'office',
	'potluck',
	'adulting',
	'china',
	'russia',
	'putin',
	'clinton',
	'twerk',
	'fart',
	'messi',
	'soccer',
	'score',
	'home',
	'apt',
	'lil',
	'biden',
	'white',
	'moves',
	'candles',
	'sales',
	'blocked',
	'whisky',
	'henny',
	'ciroc',
	'fever',
	'online',
	'zoom',
	'selfie',
	'whiteclaw',
	'coke',
	'cocaine',
	'0',
	'zero',
	'jenner',
	'kylie',
	'city',
	'girlz',
	'tongue',
	'😄',
	'👀',
	'🤔',
	'😴',
	'💣',
	'😈',
	'🐸',
	'☕️',
	'😭',
	'😍',
	'!',
	'?',
	'#'
]