import React from 'react'
import styled from 'styled-components'
import { Tab, Tabs as BPTabs } from '@blueprintjs/core'

const Tabs = styled(BPTabs)`
  &&& {
    .bp3-tab {
      color: ${({theme}) => theme.color};
      &[aria-selected="true"] {
        color: ${({theme}) => theme.primary};
      }
      &[aria-disabled="true"] {
        opacity: .75;
      }
      outline: none;
    }

    .bp3-tab-indicator {
      background: ${({theme}) => theme.secondary};
    }
  }
`

const _ = (props) => <Tabs {...props} />
_.Expander = BPTabs.Expander
_.propTypes = {

}

export { Tab, _ as Tabs }
