import React, { useState } from 'react'
import styled from 'styled-components'
import { Icon } from '@blueprintjs/core'
import _ from 'lodash'

import { Typography, Button, Dialog, Input, HR } from '../../primitives'
import { GameModes, Locale } from '../../../../utils/models'

import { useGame } from '../GameProvider'


const PlusIcon = styled(Button)`
  height: 25px;
  width: 125px;
  background: linear-gradient(to bottom, #FF416C, #FF4B2B) !important;
  /* border-radius: 50%; */
  margin-left: -.5rem;
  .bp3-button-text {
    color: ${({theme}) => theme.trueWhite} !important;
		display: flex !important;
  	align-items: center;
  	justify-content: center;
  }
`
const TeamsDialog = styled(Dialog)`
  background: ${({theme}) => theme.name === 'light' ? 'rgba(222,222,227,1)' : 'rgba(18,18,20,1)'} !important;
`
const ErrorDialog = styled(Dialog)`
  background: ${({theme}) => theme.name === 'light' ? 'rgba(222,222,227,1)' : 'rgba(18,18,20,1)'} !important;
`
const AddPlayerButton = styled(Button)`
  background-image: 
    ${({theme}) => 
		theme.name === 'light' 
			? 'linear-gradient(to bottom, #FF416C, #FF4B2B) !important'
			: 'linear-gradient(to bottom, #FF416C, #FF4B2B) !important'};
    box-shadow: 0 8px 16px -4px #FF416C54, 0 2px 4px -1px #FF4B2B54 !important;
    border-radius: 999px;
    padding: 8px 42px;
    margin-top: 36px;
    margin-bottom: 16px;
  .bp3-button-text {
    color: ${({theme}) => theme.trueWhite} !important;
  }
`
const Trash = styled(Button)`
  background-image: none;
	:not([class*="bp3-intent-"]) {
		background-color: transparent !important;
	}
  .bp3-button-text {
		text-align: center;
    color: ${({theme}) => theme.trueWhite} !important;
  }
`
const PlayerInput = styled(Input)`
  margin: 1rem 0;
  .bp3-input {
    border-radius: 999px !important;
  }
`

const ConfigureTeams = () => {
	const [open, setOpen] = useState(false)
	const [error, setError] = useState(false)
	const [playerName, setPlayerName] = useState(null)

	const [game, setGame] = useGame()


	const doAddPlayerChecks = () => {
		if (game.mode.id === GameModes.TEAM.id && (!playerName || playerName.length === 0)) {
			setError(Locale.errors.TEAM_CONFIGURATION.invalid_name)
			return
		}

		addPlayer()
	}

	const addPlayer = () => {
		if (game.players.includes(playerName)) {
			setError(Locale.errors.TEAM_CONFIGURATION.player_name_exists)
			return
		}

		setGame(prev => ({
			...prev,
			players: [
				...prev.players,
				playerName
			]
		}))

		setPlayerName(null)
	}

	const removePlayer = (player) => {
		setGame(prev => ({
			...prev,
			players: prev.players.filter((p) => p !== player)
		}))
	}

	return (
		<div className="mt-4">
			<HR className="opacity-25 mb-8"/>
			{
				<div className="mb-4">
					<Typography variant="body" weight="normal" className="mb-2">
						<span className="mb-4"><b>Who&apos;s playing (1 minimum)</b></span>
					</Typography>
					<div className="mt-4 mb-8">
						{
							(game.players).map(player => {
								return(
									<Typography key={player} variant="body" weight="normal" className="my-2">
										<span className="flex items-center">
											<span className="mr-2">💬</span> 
											{_.startCase(_.toLower(player))}
											<Trash sound="trash">
												<Icon icon="trash" iconSize="16px" className="self-center ml-4" onClick={()=>removePlayer(player)}/>
											</Trash>
										</span>
									</Typography>
								)
							})
						}			
					</div>
					{
						game.players.length < game.playerLimit && 
						<PlusIcon onClick={() => setOpen(true)}>
							<div>Add player </div>
							<Icon icon="plus" iconSize="16px" className="ml-2"/>
						</PlusIcon>
					}
				</div>
			}

			{/* Dialog components */}
			<TeamsDialog title="" showHeader={false} isOpen={open} onClose={() => setOpen(false)}>
				<div className="p-5">
					<Typography variant="h5" weight="black" className="mb-8">
						{'Add a new player'}
					</Typography>
					{
						game.mode.id === GameModes.TEAM.id
							?
							<PlayerInput
								onChange={ e => setPlayerName(e.target.value.toLowerCase())}
								placeholder="Enter your a player name"
								type="text"
							/>
							:null
					}
					
					<AddPlayerButton className="mt-4" onClick={() => {setOpen(false); doAddPlayerChecks()}}>Add</AddPlayerButton>
				</div>
			</TeamsDialog>

			<ErrorDialog title="" showHeader={false} isOpen={error} onClose={() => {setOpen(false); setError(false)}}>
				<div className="flex items-center justify-center p-5">
					<Typography variant="body" weight="normal" className="my-4">
						{error}
					</Typography>
				</div>
			</ErrorDialog>
		</div>
	)
}

export default ConfigureTeams
