const config = require('../../../firebaseConfig')

class Firebase {
	constructor(app) {
		app.initializeApp(config)

		this.auth = app.auth()
		this.doSignOut = () => this.auth.signOut()
	}
}
 
export default Firebase