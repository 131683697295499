import { WelcomePanel, ConfigurePanel } from '../../src/components/Game/Setup'

const ConfigurationPanels = [
	{
		component: WelcomePanel,
		props: {
			stepNumber: 0
		},
		title: 'Welcome'
	},
	{
		component: ConfigurePanel,
		props: {
			stepNumber: 1
		},
		title: 'Configure'
	},
	{
		component: ConfigurePanel,
		props: {
			stepNumber: 2
		},
		title: 'Configure'
	}

]

export default ConfigurationPanels