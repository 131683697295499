import styled from 'styled-components'
import Button from './Button'

const NavigationButton = styled(Button)`
	background-image: 
	${({theme}) => theme.name === 'light' 
		? 'linear-gradient(to bottom, #FF416C, #FF4B2B) !important' 
		: 'linear-gradient(to bottom, #FF416C, #FF4B2B) !important'};
	box-shadow: 0 8px 16px -4px #FF416C, 0 2px 4px -1px #FF4B2B !important;
	border-radius: 999px;
	padding: 8px 32px;
	margin-top: 36px;
	margin-bottom: 16px;
	margin-left: 1rem;
`

export default NavigationButton