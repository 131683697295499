import gql from 'graphql-tag'
import { useStaticQuery, graphql } from 'gatsby'

export const GET_TWITTER_USER = gql`
  query getUser($identifier: UserIdentifier, $identity: UserIdentity) {
    getUser(identifier: $identifier, identity: $identity){
      id
      protected
      profile_image_url
      name
    }
  }
`
export const GET_TWEETS = gql`
  query getTweets($screenName: String, $count: Int) {
    getTweets(screenName: $screenName, count: $count){
      user{
        profile_image_url
      }
      id
      created_at
      full_text
      retweet_count
    }
  }
`
export const GET_TWEETS_FROM_USERS = gql`
  query getTweetsFromUsers($screenNames: [String], $count: Int) {
    getTweetsFromUsers(screenNames: $screenNames, count: $count){
      id
      retweet_count
      favorite_count
      user{
        name
        screen_name
        profile_image_url
      }
      created_at
      full_text
      entities{
        urls{
          url
          display_url
          expanded_url
        }
    	  media{
          url
          media_url
          media_url_https
        }
      }
      extended_entities{
    	  media{
          url
          media_url
          media_url_https
        }
      }
    }
  }
`
export const GET_CATEGORY = gql`
  query category($category: String) {
    category(name: $category)
  }
`
export const GAME_CATEGORIES = gql`
  query gameCategories {
    gameCategories{
      title
      description
      imgSrc
      isCategoryMode
      order
      accounts
    }
  }
`