import React from 'react'
import PropTypes from 'prop-types'

import { Typography, SegmentedControl, GameList } from '../../primitives'
import { GameModes } from '../../../../utils/models'
import ConfigureTwitterHandles from './ConfigureTwitterHandles'
import ConfigurePlayers from './ConfigurePlayers'
import ConfigureSolo from './ConfigureSolo'

import { useGame } from '../GameProvider'

const ConfigurePanel = ({ stepNumber }) => {

	const [game, setGame] = useGame()
	
	const updateMode = (gameSelection) => {
		setGame(prev => ({
			...prev,
			selectedGame: gameSelection,
			mode: gameSelection.isCategoryMode ? GameModes.CATEGORY : GameModes.TEAM
		}))
	}

	return (
		<>
			<Typography variant="h5" weight="black" className="mb-8">
				Game Setup
			</Typography>

			<GameList onGameTapped={(gameSelection) => {updateMode(gameSelection)}}/>

			{
				game.mode.infoText.length ? 
					<div className="mb-4">
						<Typography variant="small" weight="light" className="opacity-50">{game.mode.infoText}</Typography>
					</div>
					: null
			}
			{
				game.mode.id === GameModes.TEAM.id
					? stepNumber === 1 ? <ConfigurePlayers/> : <ConfigureTwitterHandles/>
					: <ConfigureSolo title={game.selectedGame.title} description={game.selectedGame.description}/>
			}
			{
				stepNumber === 1 &&
					<div className="mt-4">
						<div className="mb-4">
							<Typography variant="body" weight="bold" className="mb-2">How many rounds do you want to play?</Typography>
							<SegmentedControl
								initialSegment={game.rounds}
								segments={['5', '10', '15']}
								changeHandler={(segment) => setGame(prev => ({ ...prev, rounds: segment }))}
							/>
						</div>
					</div>
			}
		</>
	)
}

ConfigurePanel.propTypes = {
	stepNumber: PropTypes.number
}

export default ConfigurePanel
