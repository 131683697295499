
import React, { createContext, useContext, useEffect, useState } from 'react'
import { Game } from '../../../utils/models'
import PropTypes from 'prop-types'

const Context = createContext({})

const useGame = () => (useContext(Context))


const GameProvider = ({ children }) => {
	const [game, setGame] = useState(null)

	useEffect(() => {
		if(!game) {
			const UtterGame = new Game()
			setGame(UtterGame)
		}
	}, [])

	return (
		<Context.Provider value={[game, setGame]}>
			{children}
		</Context.Provider>
	)
}
GameProvider.propTypes = {
	children: PropTypes.any
}

export { GameProvider, useGame }
