import { getRandom, onlyEmojis, hasUrlAndTextOnly, onlyURL, justBad, containsVideo } from '../functions'

import ConfigurationPanels from './ConfigurationPanels'
import GameModes from './GameModes'
import Keywords from './Keywords'
import BadWords from './BadWords'

const TEAM_LIMIT = 2 
const PLAYER_LIMIT = 8
const ACCOUNT_LIMIT = 8 

const UtterGame = () => {

	const canStartGame = (mode, players, twitterHandles) => {
		if (mode.id === GameModes.CATEGORY.id) return true
		
		let hasMinPlayers = false
		let minTwitterHandles = false

		if(mode.minPlayers > 0) {
			hasMinPlayers =  players.length >= mode.minPlayers
		} else {
			hasMinPlayers = true
		}

		if(twitterHandles && mode.minTwitterHandles && mode.minTwitterHandles > 0) {
			minTwitterHandles =  twitterHandles.length >= mode.minTwitterHandles
		} else {
			minTwitterHandles = true
		}

		return hasMinPlayers && minTwitterHandles
	}

	const hideWelcome = () => {
		return (typeof window !== 'undefined')
			? localStorage.getItem('hideUtterWelcome') !== 'true' 
				? false 
				: true
			: false
	}

	const getFormPanels = (mode) => {
		if (mode.id === GameModes.CATEGORY.id) {
			return ConfigurationPanels.slice(0,2)
		} else {
			return ConfigurationPanels
		}
	}
	
	const playersNeeded = (mode, players) => {
		return mode.minPlayers - (players).length
	}

	const getAccountPool = (selectedGame, twitterHandles) => {
		let accounts = []
		if (!selectedGame.isCategoryMode) {
			accounts = twitterHandles.map(handle=> handle.screen_name)
		} 
		else {
			accounts.push(...getRandom(selectedGame.accounts, 10))
		}

		return accounts
	}
	
	const filterTweets = (tweets, isCategoryMode) => {

		tweets = tweets.map(tweet => (
			{	
				...tweet,	
				interesting: Keywords.some(word => tweet.full_text.includes(word)),
				hasBadWords: BadWords.some(word => tweet.full_text.toLowerCase().includes(word.toLowerCase())),
				includesHandle: tweet.full_text.includes(tweet.user.screen_name),
				includesCeleb: tweet.user.name.split(' ').some(name => tweet.full_text.includes(name)),
				hasOnlyEmojis: onlyEmojis(tweet.full_text),
				hasUrlAndTextOnly: hasUrlAndTextOnly(tweet),
				onlyUrl: onlyURL(tweet),
				hasOnlyUrl: onlyURL(tweet),
				justBad: justBad(tweet),
				engagement: tweet.retweet_count + tweet.favorite_count,
				hasVideo: containsVideo(tweet)
			}
		))

		tweets = tweets.filter(tweet => !tweet.includesCeleb)
		tweets = tweets.filter(tweet => !tweet.includesHandle)
		tweets = tweets.filter(tweet => !tweet.hasBadWords)
		tweets = tweets.filter(tweet => !tweet.hasOnlyEmojis)
		tweets = tweets.filter(tweet => !tweet.onlyUrl)
		tweets = tweets.filter(tweet => !tweet.justBad)
		tweets = tweets.filter(tweet => !tweet.hasUrlAndTextOnly)
		tweets = tweets.filter(tweet => !tweet.hasVideo)

		return tweets
	}

	return {
		title: 'UTTER',
		mode: GameModes.TEAM,
		selectedGame: {},
		players: [],
		twitterHandles: [],
		category: [],
		rounds: '5',
		score: {},
		sounds: {},
		options: { sfx: true, music: true },
		teamLimit: TEAM_LIMIT,
		playerLimit: PLAYER_LIMIT,
		accountLimit: ACCOUNT_LIMIT,
		hasBegun: false,
		gameOver: false,
		tweets: null,
		currentRound: null,
		turnCount: 0,
		hideWelcome,
		playersNeeded,
		canStartGame,
		getFormPanels,
		getAccountPool,
		filterTweets,
	}
	
}
 
export default UtterGame



// [
// 				{
// 					profile_image_url: '',
// 					screen_name: '_toped'
// 				},
// 				{
// 					profile_image_url: '',
// 					screen_name: 'kanyewest'
// 				},
// 				{
// 					profile_image_url: '',
// 					screen_name: 'kingjames'
// 				},
// 				{
// 					profile_image_url: '',
// 					screen_name: 'xxl'
// 				}
// 			]