import styled from 'styled-components'
import React from 'react'

import { InputGroup } from '@blueprintjs/core'

const Styled_ = styled(InputGroup)`

    .bp3-input {
        box-shadow: none;
        padding: .75rem 1rem;
        height: inherit;
        /* border: 1px solid #cbd5e0; */
        background-color: rgba(0,0,0,.5);
        color: #FFF;
        ::placeholder{
          color: rgba(255,255,255,.5);
        }
    }

    .bp3-icon {
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0;
      margin-left: .5rem;
      color: ${({theme}) => theme.name === 'light' ? theme.trueBlack : theme.trueWhite};
    }

    .bp3-input-action {
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0;
      margin-right: .5rem !important;
      color: ${({theme}) => theme.name === 'light' ? theme.trueBlack : theme.trueWhite};
    }
`

const _ = ({ ...props }) => <Styled_ { ...props } />

_.propTypes = {

}

export default _
