const GameModes = {
	TEAM: {
		id: 1,
		title: 'teams mode',
		minPlayers: 1,
		minTwitterHandles: 2,
		infoText: '',
	},
	CATEGORY: {
		id: 2,
		title: 'category mode',
		minPlayers: 1,
		infoText: ''
	}
}

export default GameModes