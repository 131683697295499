import React from 'react'
import styled from 'styled-components'
import { Card as BPCard } from '@blueprintjs/core'

const Card = styled(BPCard)`
  &&& {
    border-radius: 0;
    border-width: 1px;
    border-style: solid;
    border-color: ${({theme}) => theme.name === 'light' ? 'rgba(16, 22, 26, 0.15)' : 'rgba(255, 255, 255, 0.5)'} !important;

    background: transparent;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.3);
  }
`

const _ = (props) => <Card {...props} />
_.propTypes = {

}

export default _