import React from 'react'
import styled from 'styled-components'

const HR = styled.hr`
  &&& {
    border-color: ${({theme}) => theme.name === 'light' ? 'rgba(16, 22, 26, 1)' : 'rgba(255, 255, 255, 0.5)'} !important;
  }
`

const _ = (props) => <HR {...props} />
_.propTypes = {

}

export default _