import styled from 'styled-components'
import Button from './Button'

const FeedbackButton = styled(Button)`
	background-image: 
	${({theme}) => theme.name === 'light' 
		? 'linear-gradient(to bottom, rgb(242,242,247), rgb(242,242,247))' 
		: 'linear-gradient(to bottom, rgb(242,242,247), rgb(242,242,247))'} !important;
	border-radius: 999px;
	padding: 8px 32px;
	margin-top: 36px;
	margin-bottom: 16px;
	margin-left: 1rem;
  .bp3-button-text {
    text-align: center !important;
  }
`

export default FeedbackButton