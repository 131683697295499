import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const Toggle = styled.input`
	display: none;
  
  :after, :before, & *, & *:after, & *:before, & + label {
    box-sizing: border-box;
  }

  ::-moz-selection, :after::-moz-selection, :before::-moz-selection, & *::-moz-selection, & *:after::-moz-selection, & *:before::-moz-selection, & + label::-moz-selection {
    background: none;
  }

  ::selection, :after::selection, :before::selection, & *::selection, & *:after::selection, & *:before::selection, & + label::selection {
    background: none;
  }

  & + label {
    outline: 0;
    display: block;
    width: 4em;
    height: 2em;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
      -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  & + label:after, & + label:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
  }
  & + label:after {
    left: 0;
  }
  & + label:before {
    display: none;
  }
  &:checked + label:after {
    left: 50%;
  }

  ${({$style}) => $style == 'light' && css`
      & + label {
      background: #f0f0f0;
      border-radius: 2em;
      padding: 2px;
      -webkit-transition: all .4s ease;
      transition: all .4s ease;
    }
    & + label:after {
      border-radius: 50%;
      background: #fff;
      -webkit-transition: all .2s ease;
      transition: all .2s ease;
    }
    &:checked + label {
      background: #9FD6AE;
    }
  `}

  ${({$style}) => $style == 'ios' && css`
    & + label {
      background: #fbfbfb;
      border-radius: 2em;
      padding: 2px;
      -webkit-transition: all .4s ease;
      transition: all .4s ease;
      border: 1px solid #e8eae9;
    }
    & + label:after {
      border-radius: 2em;
      background: #fbfbfb;
      -webkit-transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
      transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
    }
    & + label:hover:after {
      will-change: padding;
    }
    & + label:active {
      box-shadow: inset 0 0 0 2em #e8eae9;
    }
    & + label:active:after {
      padding-right: .8em;
    }
    &:checked + label {
      background: #86d993;
    }
    &:checked + label:active {
      box-shadow: none;
    }
    &:checked + label:active:after {
      margin-left: -.8em;
    }
  `}

  ${({$style}) => $style == 'skew' && css`
    & + label {
      overflow: hidden;
      -webkit-transform: skew(-10deg);
              transform: skew(-10deg);
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      -webkit-transition: all .2s ease;
      transition: all .2s ease;
      font-family: sans-serif;
      background: #888;
    }
    & + label:after, & + label:before {
      -webkit-transform: skew(10deg);
              transform: skew(10deg);
      display: inline-block;
      -webkit-transition: all .2s ease;
      transition: all .2s ease;
      width: 100%;
      text-align: center;
      position: absolute;
      line-height: 2em;
      font-weight: bold;
      color: #fff;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    }
    & + label:after {
      left: 100%;
      content: attr(data-tg-on);
    }
    & + label:before {
      left: 0;
      content: attr(data-tg-off);
    }
    & + label:active {
      background: #888;
    }
    & + label:active:before {
      left: -10%;
    }
    &:checked + label {
      background: #86d993;
    }
    &:checked + label:before {
      left: -100%;
    }
    &:checked + label:after {
      left: 0;
    }
    &:checked + label:active:after {
      left: 10%;
    }
  `}

  ${({$style}) => $style == 'flat' && css`
    & + label {
      padding: 2px;
      -webkit-transition: all .2s ease;
      transition: all .2s ease;
      background: #fff;
      border: 4px solid #f2f2f2;
      border-radius: 2em;
    }
    & + label:after {
      -webkit-transition: all .2s ease;
      transition: all .2s ease;
      background: #f2f2f2;
      content: "";
      border-radius: 1em;
    }
    &:checked + label {
      border: 4px solid #7FC6A6;
    }
    &:checked + label:after {
      left: 50%;
      background: #7FC6A6;
    }
  `}

  ${({$style}) => $style == 'flip' && css`
    & + label {
      padding: 2px;
      -webkit-transition: all .2s ease;
      transition: all .2s ease;
      font-family: sans-serif;
      -webkit-perspective: 100px;
              perspective: 100px;
    }
    & + label:after, & + label:before {
      display: inline-block;
      -webkit-transition: all .4s ease;
      transition: all .4s ease;
      width: 100%;
      text-align: center;
      position: absolute;
      line-height: 2em;
      font-weight: bold;
      color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      border-radius: 4px;
    }
    & + label:after {
      content: attr(data-tg-on);
      background: #02C66F;
      -webkit-transform: rotateY(-180deg);
              transform: rotateY(-180deg);
    }
    & + label:before {
      background: #FF3A19;
      content: attr(data-tg-off);
    }
    & + label:active:before {
      -webkit-transform: rotateY(-20deg);
              transform: rotateY(-20deg);
    }
    &:checked + label:before {
      -webkit-transform: rotateY(180deg);
              transform: rotateY(180deg);
    }
    &:checked + label:after {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
      left: 0;
      background: #7FC6A6;
    }
    &:checked + label:active:after {
      -webkit-transform: rotateY(20deg);
              transform: rotateY(20deg);
    }
  `}
`

const _ = ({ style, checked, ...props }) => {
	return (
		<div {...props}>
			<Toggle $style={style} type="checkbox" checked={checked} onChange={()=>{}}/>
			<label data-tg-off="OFF" data-tg-on="ON"></label>
		</div>
	)
}

_.propTypes = {
	style: PropTypes.string,
	checked: PropTypes.bool,
	toggle: PropTypes.func,
}
_.defaultProps = {
	style: 'skew'
}

export default _
