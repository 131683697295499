import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@blueprintjs/core'
import prettyName from 'pretty-name'
import { Context } from '../Context'

const Segment = styled(Button)`
	.bp3-button-text {
		${({theme, selected}) => {
		return `
			color: ${selected ? theme.trueWhite : theme.trueBlack} !important;
		`
	}}}


	${({theme, selected}) => {
		return `
			background: ${selected ? 'linear-gradient(to bottom, crimson, crimson)' : theme.trueWhite} !important;
		`
	}}
	
  outline: none !important;
`

const Wrapper = styled.div`
  ${Segment} {
    border-radius: 0; 
  }
  ${Segment}:not(:last-of-type) {

  }
  ${Segment}:first-of-type {
    border-top-left-radius: 999px;
    border-bottom-left-radius: 999px;
  }
  ${Segment}:last-of-type {
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px;
  }
`

const SegmentedControl = ({initialSegment, segments, changeHandler, ...props}) => {

	const [state, setState] = useState(initialSegment)
	const { sounds: soundsCtx, sfxOn: sfxOnCtx } = useContext(Context)
	const [ sounds ] = soundsCtx
	const [ sfxOn ] = sfxOnCtx

	const doSFX = () => {
		sfxOn && sounds.buttonTap.play()
	}

	const updateSelected = (selected) => {
		setState(selected)
		changeHandler(selected)
	}

	return (
		<Wrapper {...props}>
			{
				segments && segments.length > 0
					? segments.map(segment => {
						return(
							<Segment 
								key={segment}
								onClick={() => {updateSelected(segment); doSFX()}}
								selected={state === segment}
							> {prettyName(segment)}</Segment>)
					})
					: null
			}
		</Wrapper>
	)
}

SegmentedControl.propTypes = {
	initialSegment: PropTypes.string,
	segments: PropTypes.array.isRequired,
	changeHandler: PropTypes.func.isRequired
}

export default SegmentedControl
