import { useState, useEffect } from 'react'

const getLocalStorageValue = (key) => {
	let value = localStorage.getItem(key)

	if (value === undefined) { return '' }
	if (value === 'true') { return true }
	if (value === 'false') { return false }
	
	return value 
}

const useStateWithLocalStorage = (localStorageKey) => {
	const [value, setValue] = useState(
		(typeof window !== 'undefined') && getLocalStorageValue(localStorageKey)
	)
	
	if (typeof window !== 'undefined') {
		useEffect(() => {
			localStorage.setItem(localStorageKey, value)
		}, [value])
	}
 
	return [value, setValue]
}


export default useStateWithLocalStorage