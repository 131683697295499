import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Spinner } from 'evergreen-ui'
import { LoadingMessages } from '../../../utils/models'
import Typography from './Typography'

const ThemedSpinner = styled(Spinner)`
	&& {
		svg > circle {
			stroke: ${({theme}) => theme.white};
		}
	}
`

const Loading = ({ loadingMessage, size }) => {
	return (
		<div className="flex">
			<ThemedSpinner size={size} className="mr-2"/>
			<Typography variant="body">

				{
					loadingMessage || LoadingMessages[Math.floor(Math.random() * LoadingMessages.length)]
				}
			</Typography>
		</div>
	)
}

Loading.propTypes = {
	/* 
  Message to display while loading.
  If one is not provided, a random message will be generated */
	loadingMessage: PropTypes.string,
	size: PropTypes.number
}

export default Loading
