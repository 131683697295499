import React from 'react'
import PropTypes from 'prop-types'
import { ApolloProvider } from '@apollo/react-hooks'

import { client } from './client'
import ThemeController from '../Layout/ThemeController'
import { FirebaseContextProvider } from '../components/Firebase'
import { ContextProvider } from '../components/Context'
import { GameProvider } from '../components/Game/GameProvider'

export const wrapRootElement = ({ element }) => (
	<ApolloProvider client={client}>
		<GameProvider>
			<ThemeController>
				<FirebaseContextProvider>
					<ContextProvider>
						{element}
					</ContextProvider>
				</FirebaseContextProvider>
			</ThemeController>
		</GameProvider>
	</ApolloProvider>
)

wrapRootElement.propTypes = {
	element: PropTypes.any
}