import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import { Context } from '../Context' 


const useAudio = (url, autoPlay) => {
	const [audio] = useState(new Audio(url))
	const [playing, setPlaying] = useState(autoPlay)
  
	const { options: optionsCtx } = useContext(Context)
	const [ options ] = optionsCtx

	const toggle = () => setPlaying(!playing)

	useEffect(() => {
		playing && options.music ? audio.play() : audio.pause()
	},
	[playing, options]
	)

	useEffect(() => {
		audio.addEventListener('canplaythrough', () => options.music && audio.play())
		audio.addEventListener('ended', () => setPlaying(false))
		return () => {
			audio.removeEventListener('ended', () => setPlaying(false))
			audio.removeEventListener('canplaythrough', () => {})
		}
	}, [])

	return [playing, toggle]
}

const Player = ({ url, autoPlay }) => {
  
	const {themeMusic} = useStaticQuery(graphql`
		query {
			themeMusic: file(name: {eq: "ES_Beater Head - The New Fools"}, sourceInstanceName: {eq: "sounds"}) {
				absolutePath
				relativeDirectory
				relativePath
				nlink
				publicURL
			}
		}
  `)
  
	const [playing, toggle] = useAudio(url || themeMusic.publicURL, autoPlay)

	return (
		<div>
			<button onClick={toggle}>{playing ? 'Pause' : 'Play'}</button>
		</div>
	)
}

Player.propTypes = {
	url: PropTypes.string,
	autoPlay: PropTypes.bool
}

Player.defaultProps = {
	autoPlay: true
}
export default Player