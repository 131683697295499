import React from 'react'
import styled from 'styled-components'
import { Menu as BPMenu } from '@blueprintjs/core'

const Menu = styled(BPMenu)`
  &&& {

    .bp3-menu-divider {
    }

    .bp3-icon {
    }

    .bp3-menu-item {
      display: flex;
      justify-content: center;
      padding: 0.5rem;

      &:hover {

        & > .bp3-icon {
        }
      }
    }

    .bp3-menu-item.bp3-disabled {
      opacity: .75;

      & > .bp3-icon {
      }
    }

    .bp3-text-overflow-ellipsis {
        word-wrap: normal;
    }
  }
`

const _ = (props) => <Menu {...props} />
_.Item = BPMenu.Item
_.Divider = BPMenu.Divider
_.propTypes = {

}

export default _
