export default [
	'#ad',
	'now',
	'live',
	'register',
	'click',
	'link',
	'get yours today',
	'charity',
	'order',
	'1/2',
	'2/2',
	'#sponsored',
	'bio',
	'donate',
	'donating',
	'tune',
	'watch',
	'airs',
	'streaming',
	'pre-order',
	'coming',
	'clicking',
	'clicked',
	'get yours at',
	'sale',
	'show',
	'sneak peek'
]