import React, { useState } from 'react'
import styled from 'styled-components'
import { useLazyQuery } from '@apollo/react-hooks'
import { Icon } from '@blueprintjs/core'

import { Typography, Button, Dialog, Input, HR, Loading } from '../../primitives'
import { GET_TWITTER_USER } from '../../../../utils/graphql/queries'
import { GameModes, Locale } from '../../../../utils/models'

import { useGame } from '../GameProvider'


const REGEX = /[@]+/gi

const PlusIcon = styled(Button)`
  height: 25px;
  width: 125px;
  background: linear-gradient(to bottom, #FF416C, #FF4B2B) !important;
  /* border-radius: 50%; */
  margin-left: -.5rem;
  .bp3-button-text {
    color: ${({theme}) => theme.trueWhite} !important;
		display: flex !important;
  	align-items: center;
  	justify-content: center;
  }
`
const TeamsDialog = styled(Dialog)`
  background: ${({theme}) => theme.name === 'light' ? 'rgba(222,222,227,1)' : 'rgba(18,18,20,1)'} !important;
`
const ErrorDialog = styled(Dialog)`
  background: ${({theme}) => theme.name === 'light' ? 'rgba(222,222,227,1)' : 'rgba(18,18,20,1)'} !important;
`
const LoadingDialog = styled(Dialog)`
  background: ${({theme}) => theme.name === 'light' ? 'rgba(222,222,227,1)' : 'rgba(18,18,20,1)'} !important;
`
const AddPlayerButton = styled(Button)`
  background-image: 
    ${({theme}) => 
		theme.name === 'light' 
			? 'linear-gradient(to bottom, #FF416C, #FF4B2B) !important'
			: 'linear-gradient(to bottom, #FF416C, #FF4B2B) !important'};
    box-shadow: 0 8px 16px -4px #FF416C54, 0 2px 4px -1px #FF4B2B54 !important;
    border-radius: 999px;
    padding: 8px 42px;
    margin-top: 36px;
    margin-bottom: 16px;
  .bp3-button-text {
    color: ${({theme}) => theme.trueWhite} !important;
  }
`
const Trash = styled(Button)`
  background-image: none;
	:not([class*="bp3-intent-"]) {
		background-color: transparent !important;
	}
  .bp3-button-text {
		text-align: center;
    color: ${({theme}) => theme.trueWhite} !important;
  }
`
const PlayerInput = styled(Input)`
  margin: 16px 0;
  .bp3-input {
    border-radius: 999px !important;
  }
`

const ConfigureTeams = () => {
	const [open, setOpen] = useState(false)
	const [error, setError] = useState(false)
	const [twitterScreenName, setTwitterScreenName] = useState(null)

	const [game, setGame] = useGame()

	const [getTwitterUser, { loading: userLoading }] = useLazyQuery(GET_TWITTER_USER, {
		onCompleted: (data) => {
			if(data.getUser.protected) setError(Locale.errors.TEAM_CONFIGURATION.found_private_account)

			if(!data.getUser.protected) {
				addHandle(data.getUser)
			}
		},
		onError: (error) => {
			console.warn('caught error->>', error.message)
			setError(Locale.errors.TEAM_CONFIGURATION.invalid_twitter_handle)
		},
		fetchPolicy: 'network-only'
	})

	const doAddHandleChecks = () => {
		if (game.mode.id === GameModes.TEAM.id && (!twitterScreenName || twitterScreenName.length === 0)) {
			setError(Locale.errors.TEAM_CONFIGURATION.invalid_twitter_handle)
			return
		}

		if(game.mode.id === GameModes.TEAM.id){
			getTwitterUser({variables: {identity: twitterScreenName.trim().replace(REGEX, ''), identifier: 'name'}})
		} else {
			addHandle()
		}

	}

	const addHandle = (user) => {
		if (game.twitterHandles.filter(handle=> handle.screen_name === twitterScreenName).length) {
			setError(Locale.errors.TEAM_CONFIGURATION.handle_exists)
			return
		}

		setGame(prev => ({
			...prev,
			twitterHandles: [
				...prev.twitterHandles,
				{
					screen_name:twitterScreenName ? twitterScreenName.trim().replace(REGEX, '') : null, 
					profile_image_url: user.profile_image_url, 
					name: user.name, 
				}
			]
		}))

		setTwitterScreenName(null)
	}

	const removeHandle = (twitterHandle) => {
		setGame(prev => ({
			...prev,
			twitterHandles: prev.twitterHandles.filter((p) => p.screen_name !== twitterHandle.screen_name)
		}))
	}

	return (
		<div className="mt-4">
			<HR className="opacity-25 mb-8"/>
			{
				<div className="mb-4">
					<Typography variant="body" weight="normal" className="mb-2">
						<span className="mb-4"><b>Add Twitter Handles (2 minimum)</b></span>
					</Typography>
					<div className="mt-4 mb-8">
						{
							(game.twitterHandles).map(handle => {
								return(
									<Typography key={handle.screen_name} variant="body" weight="normal" className="my-2">
										<span className="flex items-center">
											<span className="mr-2">💬</span> 
											{`@${handle.screen_name}`}
											<Trash sound="trash">
												<Icon icon="trash" iconSize="16px" className="self-center ml-4" onClick={()=>removeHandle(handle)}/>
											</Trash>
										</span>
									</Typography>
								)
							})
						}			
					</div>
					{
						game.twitterHandles.length < game.accountLimit &&
						<PlusIcon onClick={() => setOpen(true)}>
							<div>Add handle</div>
							<Icon icon="plus" iconSize="16px" className="ml-2" />
						</PlusIcon>
					}
				</div>
			}

			{/* Dialog components */}
			<TeamsDialog title="" showHeader={false} isOpen={open} onClose={() => setOpen(false)}>
				<div className="p-5">
					<Typography variant="h5" weight="black" className="mb-8">
						{'Add handle'}
					</Typography>
					{
						game.mode.id === GameModes.TEAM.id
							?
							<PlayerInput
								onChange={ e => setTwitterScreenName(e.target.value.toLowerCase())}
								placeholder="Enter your twitter handle or your favorite celebrity"
								type="text"
							/>
							:null
					}
					
					<AddPlayerButton className="mt-4" onClick={() => {setOpen(false); doAddHandleChecks()}}>Add</AddPlayerButton>
				</div>
			</TeamsDialog>

			<ErrorDialog title="" showHeader={false} isOpen={error} onClose={() => {setOpen(false); setError(false)}}>
				<div className="flex items-center justify-center p-5">
					<Typography variant="body" weight="normal" className="my-4">
						{error}
					</Typography>
				</div>
			</ErrorDialog>

			<LoadingDialog title="" showHeader={false} isOpen={userLoading}>
				<div className="flex items-center justify-center p-5">
					<div className="flex my-4">
						<span>
							<Loading size={24} loadingMessage="..."/>
						</span>
					</div>
				</div>
			</LoadingDialog>
		</div>
	)
}

export default ConfigureTeams
