import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import Linkify from 'react-linkify'
import _ from 'lodash'

import Card from './Card'

const BaseCard = styled(Card)`
  padding: 0 !important;
  border-radius: 5px !important;
  background: rgba(222,222,227,1) !important;
`
const Heading = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-image: 
	${({theme}) => theme.name === 'light' 
		? 'linear-gradient(to bottom, #FF416C, #FF4B2B)' 
		: 'linear-gradient(to bottom, #FF416C, #FF4B2B)'} !important;  border-radius: 5px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding: .5rem 2rem;
  span {
    font-size: 2rem;
  }
`
const Body = styled.div`
  border-radius: 5px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 2rem;
	word-wrap: break-word;
`

const TweetCard = ({ tweet, rightChild, ...props}) => {

	const componentDecorator = (href, text, key) => (
		<a href={href} key={key} target="_blank" rel="noopener noreferrer">
			{text}
		</a>
	)

	return (
		tweet && 
				<BaseCard {...props}>
					<Heading>
						<span>💬</span>
						{rightChild}
					</Heading>
					<Body className="text-lg font-light">
						<div className="flex flex-col text-base md:text-2xl">
							{_.unescape(tweet.answer.full_text.replace(/(?:https?|ftp):\/\/[\n\S]+/g, ''))}
							<div className="flex flex-col my-2">
								{
									tweet.images.length > 0
										? tweet.images.map(image => (
											<img key={image} src={image} width="300" alt=""/>
										))
										: null
								}
							</div>
							<div className="font-medium  text-base md:text-xl my-2">{`${moment(tweet.answer.created_at, 'dd MMM DD HH:mm:ss ZZ YYYY').fromNow()} at ${moment(tweet.answer.created_at).format('LT')}`}</div>
						</div>
					</Body>
				</BaseCard>
	)
}

TweetCard.propTypes = {
	tweet: PropTypes.object,
	rightChild: PropTypes.object
}

export default TweetCard